.form-validation-error
  font-family: 'Inter', 'sans-serif'
  color: $error
  height: 20px
  font-size: 14px
  font-weight: normal
.form__field--inline
  .form__input
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
  .form__label
    margin: 0
    display: flex
    align-items: center
