.keyword-engine__search
  +card-title
  display: flex
  flex-direction: column
  font-size: 16px
  font-weight: 600
  color: $slate
  .card-section:not(.header__block)
    margin-top: 0
    border-top: 0
  .keyword-engine__search__section
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    &.keyword-engine__search__section--header
      margin-top: 0
      border-top: 0
    &.keyword-engine__search__section--term
      flex: 2
      +tablet
        border-bottom: none
    &.keyword-engine__search__section--category,
    &.keyword-engine__search__section--marketplace
      flex: 1
      +tablet
        border-top: none
    &.keyword-engine__search__section--category
      +tablet
        border-bottom: none
      +desktop
        padding-right: 0
  .marketplace-selector
    border: 1px solid $secondary_grey
    flex: 1
    max-width: none
    margin-left: 10px
    height: 40px
    +tablet
      margin-left: 0

.keyword-engine__category-selector
  width: 100%
  .trigger
    height: 40px
    .title
      color: $slate
      font-size: 14px
      font-weight: 500
