=label()
	font-size: 16px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: -0.2px
	text-align: left
	color: $slate

=body()
	font-size: 13px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.54
	letter-spacing: normal
	text-align: left
	color: $slate

=page-header()
	font-size: 22px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: -0.3px
	text-align: left
	color: $slate

=onboarding-title()
	font-size: 36px
	font-weight: 300
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: -0.5px
	text-align: left
	color: $core

=empty-state-title()
	font-size: 36px
	font-weight: 300
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: -0.5px
	text-align: center
	color: $cool_grey

=on-boarding-header()
	font-size: 36px
	font-weight: 300
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: -0.5px
	text-align: center
	color: $cool_grey

=headline-modal()
	font-size: 18px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.39
	letter-spacing: normal
	text-align: left
	color: $slate

=modal-title()
	font-size: 18px !important
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.33
	letter-spacing: normal
	color: $slate

=card-title()
	font-size: 14px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.35
	letter-spacing: normal
	text-align: left
	color: $slate

=discount-modal()
	font-size: 16px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 0.94
	letter-spacing: normal
	text-align: center
	color: $white

=onboarding-subtitle()
	font-size: 16px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: -0.2px
	text-align: left
	color: $slate

=onboarding-sub-title()
	font-size: 16px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: normal
	text-align: left
	color: $slate

=label-deactivated()
	font-size: 16px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: -0.2px
	text-align: left
	color: $cool_grey

=body-subtitle()
	font-size: 16px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: normal
	text-align: center
	color: $slate

=dashboard-data-point-label()
	font-size: 16px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: normal
	text-align: center
	color: $slate

=body-subtitle-left()
	font-size: 18px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: normal
	text-align: left
	color: $slate

=button-deactive()
	font-size: 15px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.33
	letter-spacing: -0.2px
	text-align: center
	color: $slate

=headline()
	font-size: 15px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.33
	letter-spacing: -0.5px
	text-align: left
	color: $slate

=headline-secondary()
	font-size: 15px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.33
	letter-spacing: -0.2px
	text-align: left
	color: $slate

=input-empty()
	font-size: 15px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: -0.2px
	text-align: left
	color: $cool_grey

=text-link()
	font-size: 14px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.07
	letter-spacing: normal
	text-align: center
	color: $core

=text-link-2()
	font-size: 14px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.79
	letter-spacing: normal
	text-align: left
	color: $dark

=opportunity-finder-filters()
	font-size: 10px
	font-weight: 500
	height: 18px
	letter-spacing: 0px
	line-height: 18px
	text-align: center
	color: $slate
	width: 47px

=body-secondary()
	font-size: 13px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.92
	letter-spacing: normal
	text-align: left
	color: $slate

=discount()
	font-size: 13px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.15
	letter-spacing: -0.2px
	text-align: center
	color: $white

=nav-selected()
	font-size: 13px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: normal
	text-align: center
	color: $slate

=nav-deselected()
	font-size: 13px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: normal
	text-align: center
	color: $slate

=body-legal()
	font-size: 13px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.54
	letter-spacing: normal
	text-align: left
	color: $cool_grey

=table-header()
	font-size: 13px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.35
	letter-spacing: -0.2px
	text-align: left
	color: $secondary_grey

=body-header()
	font-size: 13px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.35
	letter-spacing: -0.2px
	text-align: left
	color: $slate

=table-data-left()
	font-size: 13px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: normal
	text-align: left
	color: $cool_grey

=dashboard-data-point-small()
	font-size: 13px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: normal
	letter-spacing: normal
	text-align: left
	color: $cool_grey

=body-form()
	font-size: 12px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 2.08
	letter-spacing: normal
	text-align: left
	color: $cool_grey

=body-small()
	font-size: 12px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.6
	letter-spacing: normal
	text-align: left
	color: $slate

=footer-cta()
	font-size: 11px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.36
	letter-spacing: normal
	text-align: left
	color: $white

//----------------------------
// Styles not in Zeplin
//----------------------------

=title-description()
	font-size: 13px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.36
	letter-spacing: normal
	text-align: left
	color: $cool_grey

=filter-input()
	font-size: 12px
	height: 30px

=flex-column()
	display: flex
	flex-direction: column
	align-items: stretch

=flex-row()
	display: flex
	flex-direction: row
	align-items: stretch
	+tablet
		flex-direction: column

=onboarding-presentation()
	font-size: 36px
	font-weight: 300
	font-style: normal
	font-stretch: normal
	line-height: 1.25
	letter-spacing: -0.5px
	text-align: left
	color: $slate

=onboarding-text()
	font-size: 13px
	font-weight: 500
	font-style: normal
	font-stretch: normal
	line-height: 1.15
	letter-spacing: normal
	text-align: center
	color: $slate

=onboarding-explanation()
	font-size: 16px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.44
	letter-spacing: normal
	text-align: left
	color: $slate

=general-subtitle-bold()
	font-size: 13px
	font-weight: 600
	font-style: normal
	font-stretch: normal
	line-height: 1.54
	letter-spacing: normal
	text-align: center
	color: $cool_grey

=general-subtitle()
	font-size: 12px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.5
	letter-spacing: normal
	text-align: left
	color: $slate

=text-dark()
	font-size: 12px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.5
	letter-spacing: normal
	color: $dark

=upsell-list-item()
	font-size: 12px
	font-weight: normal
	font-style: normal
	font-stretch: normal
	line-height: 1.5
	letter-spacing: normal
	text-align: left
	color: $slate

=upsell-text-large()
	font-size: 40px
	font-weight: 300
	font-style: normal
	font-stretch: normal
	line-height: 1.04
	letter-spacing: normal
	text-align: left
	color: $slate
