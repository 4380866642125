.extension
  +body
  flex: 1
  display: flex
  flex-direction: column
  max-width: 850px
  margin: 30px auto

  .extension__header
    +onboarding_title
    display: flex
    flex-direction: row
    justify-content: center
    margin-bottom: 15px
    color: $slate
    text-align: center

  .extension__loading
    max-height: calc(100vh - 60px)
    height: 100%
    display: flex
    align-items: center

  .extension__block
    border-bottom: 1px solid $lighter_grey
    margin-bottom: 40px
    padding-bottom: 40px
    &:last-child
      border-bottom: none
      margin-bottom: 0
      padding-bottom: 10px

  .extension__subtitle
    +onboarding-sub-title
    text-align: center
    font-weight: normal
    letter-spacing: 0.2px
    line-height: 1.44
    max-width: 575px
    margin: 0 auto
    font-size: 17px
    &.extension__subtitle--small
      font-size: 13px
      margin: 15px auto 40px
      max-width: 400px

  .extension_button_row
    display: flex
    flex-direction: row 
    align-items: center
    justify-content: center
  
  button.extension__button
    margin: 20px auto 0
    max-width: 240px
    width: 100%
    
  .extension__header__image
    display: block
    margin: 0 auto 20px

  .extension__subheader
    +headline-modal
    margin-bottom: 10px
    &.extension__subheader--feature
      text-align: center
      font-size: 19px
      font-weight: 600
      letter-spacing: 0.2px
      line-height: 1.25
    &.extension__subheader--orange
      color: $core
      text-align: center

  .extension__testimonial
    display: flex
    max-width: 600px
    margin: 30px auto 15px
    justify-content: center
    align-items: center
    img
      width: 40px
      height: 40px
      margin-right: 15px
    .extension__subheader
      margin-bottom: 5px
      font-size: 16px
    .extension__testimonial__caption
      +text-link
      font-weight: 400
      font-size: 13px

  .accordion-group
    margin-top: 30px