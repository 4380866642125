@import ~../../../../../css/global/colors

.checkbox-group
  padding: 0 10px 7px 0
  display: flex
  align-items: center
  flex: 100%
  &:hover
    cursor: pointer
  &.checkbox-group--disabled
    cursor: default
  &.checkbox-group--grey 
    .checkbox-group__label--selected
      color: $slate
    .checkbox
      stroke: $slate
  .checkbox-group__label
    display: flex
    align-items: center
    color: $slate
    font-size: 12px
    transition: 300ms all
    padding: 1px 0 1px 5px
  .checkbox-group__label--selected
    color: $slate
    