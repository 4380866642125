.table--tracker
  display: flex
  flex-direction: column
  align-items: flex-start

  .table__header .table__cell.table__cell--header
    border-top: 1px solid $secondary_grey
    border-bottom: 1px solid $secondary_grey
    background-color: $background_alt
    justify-content: flex-start

  .table__title,
  .table__cell__section
    font-size: 13px

  .table__row--tracker
    flex-direction: column
    opacity: 1
    &:last-child .tracker-chart--open
      border-bottom: 1px solid $secondary_grey
    .table__row__overlay
      width: calc(100vw - 40px)
      border: none
    &.table__row--expanded .table__row__expand img
      transform: rotate(180deg)
    .table__row__cell-wrapper:hover .table__cell
      background-color: $light_yellow

  .table__row--is-being-dragged
    .tracker__dnd-wrapper
      background-color: $core
      .checkbox,
      .rectangle,
      svg
        color: $white
        rect
          stroke: $white
        line,
        path
          fill: $white

  .table__cell
    border-bottom: 0
    align-items: stretch
    justify-content: flex-start
    flex: 1
    min-width: 100px
    z-index: -4
    &:not(.table__cell--header)
      height: 125px
    &:not(.table__cell--header)
      margin-top: -1px
    &.table__cell--large
      min-width: 200px
    &.table__cell--product-name
      flex-direction: row
      justify-content: space-between
      min-width: 450px
      flex: 2
      &:not(.table__cell--header)
        padding: 0
        justify-content: flex-start
        .table__cell__column:not(.table__cell__column--image)
          padding: 12.5px 0
    &.table__cell--variant-differences
      .table__cell__section--full
        flex-direction: column
    &.table__cell--check
      flex: 0
      min-width: 60px
      .move-icon
        margin-left: 3px
    &.table__cell--actions
      z-index: auto
      min-width: 40px
      justify-content: center
    &.table__cell--rating
      min-width: 140px

  .table__row__expand
    color: $secondary_grey
    cursor: pointer
    display: flex
    justify-content: center
    position: absolute
    bottom: 0
    width: 80px
    padding: 10px 0px
    transition: left 0.3s linear
    +desktop
      left: calc(50vw - #{$desktop-side-margin} * 2 - #{$sidebar-full-width} / 2)
    +tablet
      left: calc(50vw - #{$tablet-side-margin} * 2 - #{$sidebar-full-width} / 2)
    &.table__row__expand--collapsed-sidebar
      +desktop
        left: calc(50vw - #{$desktop-side-margin} * 2 - #{$sidebar-collapsed-width} / 2)
      +tablet
        left: calc(50vw - #{$tablet-side-margin} * 2 - #{$sidebar-collapsed-width} / 2)

    img
      height: 12px

  .table__cell__column
    margin-right: 0

  .table__cell__column--image
    padding: 0
    min-width: 55px
    width: 55px
    margin: 0 10px 0 30px

  .table__cell__section--full
    flex-direction: row

  .tracker__dnd-wrapper
    padding: 12.5px
    cursor: move
    &.tracker__dnd-wrapper--disabled
      cursor: auto
    .check-box-icon
      flex-basis: 20px
    .move-icon
      flex-basis: 100%
      padding-left: 4px

  .outlier-icon
    position: absolute
    top: calc(50% - 8px)
    left: 42.5px

.table--tracker,
.table--tracker--overview
  .table__row__cell-wrapper
    display: flex
    flex-direction: column
    align-items: stretch
    position: relative

.table--tracker .table__row__cell-wrapper
  border-bottom: 1px solid $secondary_grey

.table--tracker--overview
  .table__title,
  .table__cell__section
    font-size: 13px
  .table__header
    .table__cell.table__cell--header
      background-color: $background_alt
  .table__row__overlay--isLoading
    height: calc(100% - 10px)
    top: 10px
    border-bottom: none
