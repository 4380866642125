.modal-container.tracker-notes
  width: 100%
  max-width: 80%
  left: 10%
  transform: translate(0, -50%)
  +tablet
    max-width: 90%
    left: 5%
  .modal-content
    display: flex
    align-items: stretch
    flex-wrap: wrap
    position: relative
  .tracker-notes__description
    flex-basis: 100%
    margin: 5px 0px 20px
  .tracker-notes__list
    +flex-column
    width: 100%
    max-width: 400px
    border-radius: 1px
    margin: 0 0 55px
    height: 350px
    border: 1px solid $secondary_grey
    +tablet
      max-width: 250px
    .tracker-notes__header
      flex: 1
      font-weight: 500
      background-color: $background
      padding: 10px
    .tracker-notes__wrapper
      flex: 10
      overflow: scroll
      margin: 0 -1px
      position: relative
    .tracker-notes__table
      display: table
      width: 100%
      border-collapse: collapse
    .tracker-notes__table__row
      display: table-row
      border: 1px solid $secondary_grey
      background: $white
      transition: all 100ms ease
      cursor: pointer
      &:hover,
      &.tracker-notes__table__row--selected
        border-color: $core
        .tracker-notes__table__cell
          border-top: 1px solid $core
          color: $core
          .inline-editor
            color: $core
            font-weight: 600
    .tracker-notes__table__cell
      display: table-cell
      vertical-align: middle
      padding: 10px
      height: 45px
    .tracker-notes__table__cell__content
      display: flex
      justify-content: space-between
      align-items: center
      .inline-editor
        flex: 2
        line-height: normal
        height: 22px
        padding-right: 5px
        .inline-text
          white-space: nowrap
          overflow: hidden
          max-width: 250px
          text-overflow: ellipsis
        .inline-input
          height: auto
          min-height: auto
          padding: 0
          font-size: inherit
          letter-spacing: 0
          color: $slate
          overflow: scroll
          max-width: 250px
          &:focus
            font-weight: normal
            color: $core
            outline-offset: 0px
            border-bottom: none
      .inline-buttons
        display: flex
        align-items: center
        position: absolute
        right: 1px
        padding-left: 5px
        background-color: $white
