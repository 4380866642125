.registrations__upsell
  display: flex
  flex-direction: column
  align-items: center
  .registrations__upsell__content
    max-width: 410px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px
    .registrations__upsell__block 
      margin-top: 15px
      margin-bottom: 10px
    .registrations__upsell__header
      +onboarding-presentation
    .registrations__upsell__text
      +onboarding-explanation
    .registrations__upsell__subtitle
      +general-subtitle
    .registrations__upsell__subtitle--bold
      +general-subtitle-bold
      &:hover
        cursor: pointer
    .registrations__upsell__title
      +headline-modal
    .registrations__upsell__actions
      width: 100%
      align-items: center
      display: flex
      flex-direction: column
      margin: 30px 0 -10px
      .registrations__upsell__button
        width: 80%
  .registrations__upsell__content--left
    align-items: flex-start
  .registrations__upsell__content--large
    max-width: none
    width: auto
    padding: 0 15px
