.table--admin
  .table__cell--header
    border-top: solid 1px $secondary_grey
  
  .table__body .table__row:hover .table__cell
    transition: 300ms all
    background-color: $lighter_grey
    cursor: pointer

  .row-cell
    overflow-wrap: break-word
    word-wrap: break-word
    word-break: break-word

  .text_xxlarge
    max-width: 200px
