.registrations
  flex: 1
  display: flex
  flex-direction: column
  min-height: 100vh
  background-color: #DDE2E4 // special background color that improves conversions
  padding: 70px 20px 20px 20px
  +tablet
    padding-top: 60px
  .alipay__loading
    position: relative
    min-height: 300px