.fourofour-page
  width: 100%
  padding-top: 100px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  .title
    +on-boarding-header
    margin: 17.4px 0
  .subtitle
    +body-subtitle
    margin: 5px 0 0
  .instruction
    max-width: 515px
    text-align: center
    .headline
      +body-header
      margin: 33px 0 4.5px
  .error-logo
    display: block
    margin-right: auto
    margin-left: auto
    width: 70px
    max-width: 100%