.admin-dashboard-page
  flex: 1
  display: flex
  flex-direction: column

  .admin-dashboard-page__row
    flex: 1
    display: flex
    justify-content: space-between
    align-items: stretch
    +tablet
      flex-direction: column

    .admin-dashboard-page__row__cell
      display: flex
      flex-direction: column
      flex: 1
      &:not(:first-child):not(:last-child)
        +desktop
          margin: 0 15px
      .list-table
        display: flex
        flex-direction: column
      .card-section:last-child
        flex-basis: 100%
        display: flex
        align-items: stretch
      .list-table-header
        margin-top: 0
        border-top: 0
