$num-width: 100px
$num-width_sml: 50px
$img-width: 150px
$cta-width: 10px
$custom-width-1: 200px
$custom-width-2: 300px

.table
  flex: 1
  display: flex
  flex-direction: column
  .table-row
    display: flex
    flex-direction: row
    justify-content: space-between
    .row-cell
      display: flex
      justify-content: flex-start
      align-items: center
      margin-left: 10px
      margin-right: 10px
      &.label
        +card-title
      &.center
        justify-content: center
      &.number
        flex-basis: $num-width
      &.number_sml
        flex-basis: $num-width_sml
      &.text
        flex: 1
        flex-wrap: wrap
      &.text_xsml
        flex: 0.25
        flex-wrap: wrap
      &.text_sml
        flex: 0.5
        flex-wrap: wrap
      &.text_large
        flex: 1.5
        flex-wrap: wrap
      &.text_xlarge
        flex: 2
      &.text_xxlarge
        flex: 3
      &.image
        flex-basis: $img-width
        max-width: 150px
      &.cta
        flex-basis: $cta-width
      &.custom-width-1
        flex-basis: $custom-width-1
      &.custom-width-2
        flex-basis: $custom-width-2
      &.no-border
        border: none
        margin: 0
        color: $core
      &.button
        flex: 1.5
        justify-content: flex-end
      &.content
        flex: 1
      &.content-button
        flex: 1
        justify-content: space-between
        align-items: center
      &.select
        flex: 1
        justify-content: space-between
        align-items: center
        .card-section
          flex: 1

  .wrapper
    display: flex
    flex-direction: row
    flex-grow: 0
    flex-wrap: wrap

.table--keyword,
.table--database,
.table--tracker--overview
  overflow: auto
  width: 100%

.table--tracker
  overflow: auto
  .table__scrolling-container
    width: fit-content
    min-width: 100%

.table--keyword
  .table__scrolling-container
    min-width: 930px
.table--database
  .table__scrolling-container
    min-width: $min-table-width

.table--keyword-engine-feature
  overflow: scroll
  .table__scrolling-container
    min-width: $small-desktop-width

.table--no-results
  .table__cell .table__title,
  .table__cell .table__title--sorted
    color: $cool_grey
    .table__wrapper:hover .table__content
      display: none
    .sortable-icon
      display: none

.table__empty
  position: relative
  min-height: 300px
  width: 100%
  .loading__wrapper
    background: transparent

.table__results
  +card-title
  display: flex
  justify-content: space-between
  align-items: center
  .table__title
    span
      margin: 0 4px 0 8px

.table__header
  transform: none

.table__row
  display: flex
  justify-content: space-between
  align-items: stretch
  position: relative
  flex-basis: 100%
  .table__title
    width: 100%
    display: flex
    justify-content: space-between

.table--keyword
  .table__cell
    flex: 10% 0 1
    min-width: 120px
  .table__cell--keyword-name,
  .table__cell--secondary
    min-width: 150px

.table__cell
  +body
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex: 1
  &:not(:first-child):not(.table__cell--header)
    border-left: 0
  &:not(:last-child)
    border-right: 0
  &.table__cell--header
    margin-top: 0
    border-top: 0
    align-items: center
    justify-content: center
    &:not(.table__cell--checkbox)
      cursor: pointer
    .fa
      padding-right: 3px
    &.table__cell--actions
      cursor: normal
  &.table__cell--secondary
    flex-grow: 1.5
  &.table__cell--large
    flex-grow: 1.75
  &.table__cell--small
    flex-grow: 1.25
  &.table__cell--overflow
    overflow-x: auto
  &.table__cell--centered
    justify-content: center
  &.table__cell--product-name
    flex-basis: 20%
    justify-content: flex-start
    flex-direction: column
    +desktop
      flex-direction: row
      flex-basis: 30%
  &.table__cell--keyword-name a
    color: $core
    font-weight: 500
  &.table__cell--actions
    align-items: stretch
    justify-content: center
    flex-grow: 0.25
  &.table__cell--check
    flex-grow: 0.1
    padding: 0
    display: flex
    align-items: stretch
    .table__title
      align-items: stretch
  &.table__cell--empty
    flex-basis: calc(100% - 200px)
    .table__cell__section--full
      justify-content: center
      align-items: center
      color: $slate_50
.table__cell.table__cell--sticky,
.table__cell.table__cell--sticky.table__cell--header,
.table__cell.table__cell--sticky.table__cell--product-name
  position: sticky
  left: 0
  z-index: 0
  background-color: $white
  -webkit-backface-visibility: hidden
  padding: 0
  .table__row
    justify-content: flex-start
    align-self: stretch
    & > div
      padding: 12.5px
      align-items: center
      display: flex

.table__cell__column
  display: flex
  flex-direction: column
  align-items: stretch
  &:not(.table__cell__column--image)
    flex: 1
.table__cell__column--image
  margin-right: 15px
  padding: 15px 0px
  min-width: 165px
  align-items: center
  justify-content: center
  img
    max-height: 100px
    max-width: 100%
    width: auto
.table__cell__column--actions
  justify-content: space-between
  margin-right: 0
.table__cell__column--checkone
  cursor: move
  width: 70px
  margin: 0
  position: relative
  display: flex
  align-items: center
  justify-content: center
  font-size: 22px

.table__cell__section
  +card-title
  display: flex
.table__cell__section--name,
.table__cell__section--name a
  color: $core
  font-weight: 500
  font-size: 15px
.table__cell__section--name a
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  line-height: 22px
  max-height: 45px
  margin-bottom: 10px
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
.table__cell__section--full
  font-weight: normal
  padding-bottom: 5px
  display: flex
  flex-direction: column
  width: 100%
  +desktop
    flex-direction: row
.table__cell__section--label
  font-weight: 500
  align-self: flex-start
  letter-spacing: -0.5px
  flex-basis: auto
  +desktop
    flex-basis: 85px
.table__cell__section--text
  font-weight: normal
  flex: 1
.table__cell__section--centered
  justify-content: center
  text-align: center
.table__cell__section--middle
  align-items: center
.table__cell__section--padding-left
  padding-left: 5px
.table__cell__section--asin
  text-align: right
.table__cell__section--light
  color: $cool_grey
  padding-top: 5px
.table__cell__section--actionable
  cursor: pointer
  transition: 300ms color ease
  color: $core
  .table__cell__mini
    color: $slate
  &:hover
    color: $dark

.table__cell__mini
  width: 60px
  font-weight: 500
  letter-spacing: -0.5px

.table__cell__checkone
  color: $slate_90
  position: absolute
  top: 15px
  cursor: pointer
  .fa-ellipsis-v
    color: $core
    font-size: 30px
    padding-top: 15px

.table__row__overlay
  display: flex
  justify-content: space-around
  align-items: center
  position: absolute
  z-index: 10
  left: 0
  top: 0
  right: 0
  bottom: 0
  margin-top: -1px
  background-color: transparent
  border: 1px solid $secondary_grey
.table__row__overlay--isLoading
  background-color: $white_50
.table__row__overlay--product-just-added
  animation: add-to-tracker 2s ease
