.admin-user
  display: flex
  flex-direction: column

  @media only screen and (max-width: 940px)
    width: calc(100% - 264px)

  @media only screen and (min-width: 941px)
    width: calc(100% - 365px)

  .admin-user__content
    display: flex
    +tablet
      flex-direction: column
    .admin-user__column
      flex: 1
      display: flex
      flex-direction: column
      &:first-child
        margin-right: 15px
      &:last-child
        margin-left: 15px

  .admin-user__section--charges,
  .admin-user__section--subscription,
  .admin-user__section--notes
    .list-table-header
      margin-top: 0
      border-top: 0
    .list-table-content
      border-bottom-style: solid
    .row-cell.button button
      margin: 0
    .table-row
      padding: 15px 0px
      &:last-child
        border-bottom: none

  .admin-user__user-invites
    .card-section:last-child
      justify-content: flex-start
      align-items: flex-start
      min-height: 0
    .table
      .empty-content
        margin: 1.5em 0
      .list-table-content
        padding: 1.5em 0
        border-bottom-style: solid
        &:last-child
          border-bottom: 0px

  .admin-user__section--charges
    .list-table-content
      border-bottom: 1px solid #C2C2C2
    .table-row
      align-items: center
      padding: 10px 0
      line-height: 2
      &:last-child
        border-bottom: none
      .row-cell
        margin: 0 3px
        align-items: flex-start
        &.text_xlarge
          text-overflow: ellipsis
          display: inline-block
          overflow: hidden
          &:hover
            overflow: scroll
            text-overflow: clip
        &.button button
          min-width: auto
          padding: 5px
        .admin-user__section--charges__button-wrapper
          min-width: 50px
          display: flex
          justify-content: space-around
          flex: 1
        button.admin-user__section--charges__button--expand
          min-width: 35px
          max-width: 35px
          margin-left: 5px
          padding: 0

  .admin-user__section--refunds
    flex-basis: 100%
    display: none
    .card-section
      border: none
      padding: 0
      min-height: 0
    .empty-content
      padding: 20px 0 10px
      font-size: 13px
    &.admin-user__section--refunds--visible
      max-height: 100%
      border-top: 1px solid $secondary_grey
      margin-top: 10px
      display: block

  .refund-modal
    display: flex
    justify-content: center
    align-items: center
    .modal-container
      overflow: visible
      width: 30%
    .refund-modal__section
      margin: 5px 0px
    .refund-modal__content
      display: flex
      flex-direction: column
    .refund-modal__form
      border-top: 1px solid $secondary_grey
      margin-top: 10px
      padding: 10px 0px
    .refund-modal__title
      font-weight: 600
    .refund-modal__input-title
      font-weight: 500
      margin-top: 5px
    .Select
      margin: 5px 0px 10px 0px
    .refund-modal__input
      margin: 5px 0px 5px 0px
      width: 100%
      padding-left: 10px

  .subscription-modal
    display: flex
    justify-content: center
    align-items: center
  .subscription-activity-modal-container
    max-height: 500px
    width: 70%

  .admin-user__form
    flex: 1
    .admin-user__form__field
      flex-basis: 100%
      padding: 5px
    .admin__buttons
      margin-top: 15px
    .form__input
      flex-direction: row
      .form__label
        flex: 1
        font-size: 14px
        margin-right: 15px
        height: 30px
        display: flex
        align-items: center
      input
        font-size: 14px
        height: 35px
        padding: 0
        flex: 2
        margin: 0
      .Select, .Select-control, .Select-input
        flex: 2

  .admin-user__notice
    +card-title
    color: $core
    margin: 5px 0px 15px

  .admin-user__tabs
    padding: 0
    border-bottom: none
    flex-wrap: wrap
    .admin-user__tab
      +page-header
      flex: 1
      border-top-left-radius: 6px
      border-top-right-radius: 6px
      background-color: $background
      border: solid 1px $secondary_grey
      font-size: 14px
      padding: 15px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      white-space: nowrap
      &:first-child
        border-left: none
      &:not(:last-child)
        border-right: 1px solid $secondary_grey
      &.admin-user__tab--active
        background-color: $white
        border-bottom-color: $white
