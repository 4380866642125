.table--keyword
  .table__header
    .table__cell--header
      background-color: #F9F9F9
      border-top: 1px solid $secondary_grey
  .table__row--keyword
    flex-direction: column
    position: initial
    &.table__row--expanded .table__cell
      border-bottom: 0
  .table__row--keyword:nth-of-type(even)
    .table__cell
      background-color: $background_alt
  .table__row--keyword-cells
    cursor: pointer
    .table__cell
      margin-top: 0
      border-top: none
      .table__cell__section
        font-size: 12px

  .table__cell
    flex-direction: column
    align-items: stretch
    &.table__cell--border-right
      border-right: 1px solid $secondary_grey
    &.table__cell--keyword-name
      flex-basis: 20%
    .tooltip__wrapper
      align-self: flex-start
  .table__cell--keyword-name
    flex-basis: 20%
    justify-content: center
    .table__cell__section--name
      align-items: center
    .flag
      margin-left: auto
      border: 1px solid $core
    .table__cell--keyword-name__caret
      margin-right: 15px
      height: 10px
      &.closed
        transform: rotate(-90deg)
        transition: all 300ms
      &.open
        transform: rotate(90deg)
        transition: all 300ms
  .table__cell--centered
    justify-content: flex-start
  .table__cell--score
    padding-bottom: 0
    flex-direction: column
    align-items: stretch
    .score__bar
      width: 200%
      height: 10px
      margin: 5px -13px 0px
      &.score__bar--green
        background-color: $success
      &.score__bar--yellow
        background-color: $warning
      &.score__bar--red
        background-color: $error

  .table__cell__column--keyword-image
    position: relative
    border-radius: 1px
    border: solid 1px $secondary_grey
    .table__cell__gradient-overlay
      position: absolute
      width: 100%
      height: 100%
      background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(81,73,73,0.1) 60%, rgba(58,52,52,0.9) 100%)
      opacity: 0.7
    .table__cell__add-to-tracker
      position: absolute
      left: 5px
      bottom: 5px
  .table__cell__column--keyword-name
    flex: 2
    justify-content: space-between
  .table__cell__column--keyword-brand
    flex: 1

  .table__cell__section--reviews
    align-items: center
    span
      display: flex
      align-items: center
      padding-right: 5px
    .star-rating
      padding-right: 5px
      padding-top: 0.5px

  .table__cell--keyword-products-name
    .table__cell__column--image
      height: 100px
    .table__cell__column:nth-child(2)
      flex-direction: row
      flex-wrap: wrap
    .table__cell__section--full
      flex-basis: 100%
      +small-desktop
        flex-basis: 50%
      +large-desktop
        flex-basis: 45%
    .table__cell__section--name
      flex-basis: 100%
    .table__cell__section--label
      flex-basis: 70px
