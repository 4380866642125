.account
  .table__body--billing
    position: relative
    min-height: 200px
    .loading__wrapper
      background: transparent

  .table__row--billing:first-child .table__cell
    margin-bottom: 15px
    
  .table__row--billing:not(:first-child) .table__cell,
  .table__row--users:not(:first-child) .table__cell
    margin-top: 0

  .table__row--billing:nth-child(n+3) .table__cell
    border-top: 0

  .table__cell
    justify-content: flex-start
    align-items: stretch

  .table__cell--check
    flex: none
    flex-basis: 60px
    .fa
      padding: 0
    .check-all__icon
      padding: 0 10px 0 0
    &:not(.table__cell--header)
      padding: 12.5px
      [class^="Wrapper"]
        justify-content: center

  .table__cell--actions
    font-size: 18px
    justify-content: center
    align-items: stretch

  .table__cell--header:not(:first-child)
    border-left: 0
  
  .table__cell__column
    margin: 0

  .table__cell__section--full
    padding-bottom: 0


.table--users 
  .table__cell
    border: 0
    padding: 5px
  .table__cell--actions:not(.table__cell--header)
    cursor: pointer
  
  .table__cell__section
    +title-description
    color: $slate
    margin-top: 3px