.header.header--tracker
  display: flex
  align-items: stretch
  flex-wrap: wrap

  .card-section
    flex: 1
    &.card-section--header
      flex-basis: 100%
  
  .tracker__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end
    flex: 1
    .button
      flex: 1

  .header__section--tracker
    flex: 2
    display: flex
    align-items: center
    max-width: 450px
    width: 100%
    flex-wrap: wrap
    margin: 0 5px 0 15px
    +phone
      padding-top: 15px
     

  .header__text
    font-size: 13px
    line-height: 1.25
    letter-spacing: -0.3px
    text-align: left
    color: $cool_grey
    font-weight: normal
    flex-basis: 100%
    display: flex
    justify-content: space-between