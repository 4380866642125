.header__block
  +page-header
  display: flex
  flex-direction: row
  justify-content: space-between
  border-radius: 0
  margin-top: 15px
  +phone
    flex-direction: column
  &:first-child
    margin-top: 0
  &.header__block--tutorial
    display: flex
    justify-content: flex-end
    font-size: 13px
    font-weight: 600
    color: $dark
    background: none
    border: none
    padding: 0
    margin-top: -15px
    button
      margin-right: 0
      padding: 5px 10px
      min-height: 30px