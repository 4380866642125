@import global/colors
@import global/variables
@import global/text_styles
@import global/forms

@import account/index
@import admin/index
@import extension/index
@import keyword/index
@import keyword_engine/index
@import keyword_list/index
@import onboarding/index
@import registrations/index
@import sessions/index
@import tracker/index
@import ui_elements/index

*
  box-sizing: border-box

body
  background-color: $background
  font-family: $font-default
  font-size: 14px
  margin: 0
  line-height: 1.428571429
  a
    text-decoration: none
    color: $lightpurple

#app-content
  box-sizing: border-box
  position: relative
  min-height: 100vh
  display: flex

.tooltip-max
  max-width: 300px

ul
  margin-top: 0px
  margin-bottom: 10px

.loading-page
  position: relative
  height: 100vh

.loading__wrapper
  position: absolute
  width: 100%
  height: 100%
  z-index: 10
  background-color: $white_50
  display: flex
  justify-content: center
  align-items: center
  left: 0
  top: 0

.empty-state__reduced-top-margin
  margin-top: 50px

.empty-content
  +empty-state-title
  font-size: 18px
  width: 100%

.notifications-tr
  z-index: 1000000 !important
