.admin-membership-types
  flex: 1
  display: flex
  flex-direction: column

  .admin-membership-types__show-table
    .admin-membership-types-input-field
      height: 30px
      padding: 0px 10px
      margin-top: 0px
      +body-form