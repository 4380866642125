@import ~../../../../../css/global/colors
@import ~../../../../../css/global/text_styles

.multiselect
  width: 47%
  cursor: pointer
  position: relative
  &.disabled
    background-color: $background
    pointer-events: none
    .actions .arrow
      display: none
  &.error .trigger
    border-color: $error
    .title
      color: $error
  &.multiselect--open .trigger
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
  .trigger
    padding: 0 10px
    align-items: center
    display: flex
    height: auto
    min-height: 35px
    border-radius: 4px
    border: solid 1px $lighter_grey
    justify-content: space-between
    .title
      +input-empty
      color: $slate_50
      font-size: 12px
  .actions
    display: flex
    align-items: center
    .counter
      background-color: $core
      width: 19px
      height: 19px
      padding: 3px
      margin-right: 4px
      border-radius: 1px
      +discount
    .arrow
      width: 0
      height: 0
      border-left: 4px solid transparent
      border-right: 4px solid transparent
      margin: 3px 0 0 3px
      &.arrow-up
        border-bottom: 4px solid $black
      &.arrow-down
        border-top: 4px solid $black
  .dropdown
    z-index: 320
    position: absolute
    border: solid 1px $lighter_grey
    background-color: $white
    border-top: none
    width: 100%
    max-height: 250px
    overflow-y: hidden
    .loading-container
      display: flex
      height: 20px
      width: 100%
      div
        margin-left: auto
        margin-right: auto
    .options-container
      overflow-y: auto
      max-height: 200px
      &.is-loading
        max-height: 180px
      .options
        list-style-type: none
        margin: 0
        padding: 0
        .option
          padding: 8px 10px
          list-style-type: none // FOR IE
          display: flex
          height: auto
          align-items: center
          .display-name
            margin-left: 4px
            +text-link-2
            line-height: 1.38
            color: $slate
            font-weight: 400
            font-size: 12px
          &.selected
            .display-name
              color: $slate
          &.inactive
            +body-secondary
            opacity: 0.5
            pointer-events: none
          .radio
            width: 10px
            height: 10px
            border-radius: 100px
            background-color: $white
            border: solid 1px $secondary_grey
            margin-top: 0
            display: flex
            justify-content: center
            align-items: center
            .selection
              width: 6px
              height: 6px
              border-radius: 100px
              &.selected
                background-color: $core
  .no-show
    display: none
  .search-box
    margin: 5px
    width: calc(100% - 10px)
