// variables for responsive sizing
$tablet-width: 455px
$desktop-width: 940px
$small-desktop-width: 1200px
$min-table-width: 1380px
$large-desktop-width: 1440px
$navbar-break-width: 1290px

=phone()
  @media only screen and (max-width: #{$tablet-width})
    @content

=tablet()
  @media only screen and (max-width: #{$desktop-width})
    @content

=desktop()
  @media only screen and (min-width: #{$desktop-width})
    @content

=small-desktop()
  @media only screen and (min-width: #{$small-desktop-width})
    @content

=large-desktop()
  @media only screen and (min-width: #{$large-desktop-width})
    @content

=navbar-break-width()
  @media only screen and (max-width: #{$navbar-break-width})
    @content

// fonts
$font-default: 'Inter', sans-serif
$fontlobster: 'Lobster', cursive

$font-weight-body-form: 12px

// toggle
$toggle-circle-radius: 10px
$toggle-border-width: 1px

// views' (e.g. Product Tracker's) left/right margins
$desktop-side-margin: 30px
$tablet-side-margin: 10px

// sidebar
$sidebar-full-width: 230px
$sidebar-collapsed-width: 74px
