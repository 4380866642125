.tracker-additional-info
  display: flex
  flex: 1
  flex-direction: column
  padding: 20px
  .additional-info__title
    +text-link
    text-align: left
    margin-bottom: 15px

  .additional-info__table
    display: flex
    flex-direction: column
    flex: 1
    justify-content: space-between
    .table__header
      display: flex
      flex-direction: row
      justify-content: space-between
    .table__row
      display: flex
      flex-direction: row
      justify-content: space-between
    .table__row__cell
      justify-content: flex-start
      padding-top: 5px
      padding-bottom: 6px
      padding-left: 5px
      border-bottom: solid 1px $secondary_grey
      +body-secondary
      font-weight: 300
      &.header
        background-color: $background
        border-top: 1px solid $secondary_grey
        border-left: 1px solid $secondary_grey
        border-bottom: 1px solid $secondary_grey
        font-weight: 500
      &:first-of-type
        border-left: 1px solid $secondary_grey
      &:last-of-type
        border-right: 1px solid $secondary_grey
      &.category
        flex-basis: 300px
      &.number
        flex: 1
      &.text
        flex: 1
        overflow: hidden
