.tracker
  flex: 1
  display: flex
  flex-direction: column
  +desktop
    margin: 30px
  +tablet
    margin: 10px

@keyframes add-to-tracker
  50%
    background-color: $core_10