.admin-extension-types
  flex: 1
  display: flex
  flex-direction: column

  .admin-extension-types__content
    display: flex
    .admin-extension-types__column
      flex: 1
      display: flex
      flex-direction: column
      &:first-child
        margin-right: 15px
      &:last-child
        margin-left: 15px

    .admin-extension-types__column.new-form
      margin: 0

    .admin-extension-types__show-table
      .admin-extension-types-input-field
        height: 30px
        padding: 0px 10px
        margin-top: 0px
        +body-form