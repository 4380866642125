.onboarding-parent .onboarding-module
  display: flex
  flex-direction: column
  align-items: center
  padding: 70px 20px 20px 20px

  .onboarding__js-university
    color: $core
    &:hover
      color: $dark

  .onboarding-header
    width: 100%
    height: 70px
    display: flex
    background-color: $white
    box-shadow: 0 1px 0 0 $secondary_grey
    padding-left: 50px

  .onboarding-questions, .onboarding-questions__buttons
    width: 100%
    margin-top: 50px
    max-width: 540px
    .onboarding-questions__counter
      +body-form
      text-align: right
    .onboarding-questions__buttons
      margin-top: 25px
      display: flex
      flex-direction: row
      justify-content: space-between
    .onboarding-question
      flex-direction: column
      align-items: flex-start
      padding: 0 30px 0 30px
      margin-top: -6px
      border-top: none
      &:not(:last-of-type)
        border-bottom: none
      &:last-of-type
        padding-bottom: 30px
      .onboarding-question__header
        +onboarding-presentation
        margin-top: 35px
      .onboarding-question__explanation
        +onboarding-explanation
        margin-top: 15px
      .onboarding-question__text
        +headline-secondary
        margin-top: 30px
      .onboarding-question__answers
        display: flex
        flex-wrap: wrap
        flex-direction: row
        width: 100%
        .onboarding-question__answer--single
          width: 100%
          margin-top: 10px
        .onboarding-question__answer
          width: 105px
          height: 105px
          margin-top: 20px
          display: flex
          align-items: center
          flex-direction: column
          justify-content: space-evenly
          border-radius: 2px
          border: solid 1px $secondary_grey
          +onboarding-text
          margin-right: 13px
          padding: 2px
          &:hover
            cursor: pointer
          .tooltip__content
            margin-bottom: 7px
            white-space: nowrap
            &::after
              left: 16px
          .onboarding-question__answer__img
            width: 47px
            height: 47px
        .onboarding-question__answer--small
          width: 40px
          height: 40px
        .onboarding-question__answer__overlay
          position: absolute
          width: 25px
          margin: 1px 2px
          background: rgba(255,255,255, 0.7)

  .onboarding-questions__buttons
    margin-top: 25px
    display: flex
    flex-direction: row
    justify-content: space-between