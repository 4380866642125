// from Zeplin
$light: #ffb24c
$core: #ff9c00
$core_10: rgba(255, 145, 0, 0.1)
$dark: #ff9100
$dark_10: rgba(255, 145, 0, 0.1)
$blue: #042BDC
$blue_10: rgba(4, 43, 220, 0.1)
$white: #ffffff
$white_0: rgba(255, 255, 255, 0)
$white_15: rgba(255, 255, 255, 0.15)
$white_50: rgba(255, 255, 255, 0.5)
$white_75: rgba(255, 255, 255, 0.75)
$black: #000000
$black_10: rgba(0, 0, 0, 0.1)
$black_15: rgba(0, 0, 0, 0.15)
$black_20: rgba(0, 0, 0, 0.2)
$black_60: rgba(0, 0, 0, 0.6)
$slate: #222
$slate_10: rgba(34, 34, 34, 0.1)
$slate_50: rgba(34, 34, 34, 0.5)
$slate_75: rgba(34, 34, 34, 0.75)
$slate_90: rgba(34, 34, 34, 0.9)
$charcoal_grey: #4a4a4a
$secondary_grey: #E5E5E5
$dark_grey: #656565
$darker_grey: #7b7b7b
$darker_grey_75: rgba(123, 123, 123, 0.75)
$lighter_grey: #e5e5e5
$lightest_grey: #eff1f2
$blue_grey: #9B9B9B
$cool_grey: #90a4ae
$cool_grey_dark: #91a1aa
$cool_grey_15: rgba(144, 164, 174, 0.15)
$warm_grey_60: rgba(155, 155, 155, 0.6)
$transparent_grey: rgba(0, 0, 0, 0.4)
$brown_grey: #9b9b9b
$light_blue: #e0f7fa
$medium_blue: #8cbff9
$bright_blue: #23b4e6
$opaque_blue: #e2e7fb
$vivid_blue: #03e5ff
$alert_blue: #042BDC
$error: #ed455a
$error10: rgba(237, 70, 90, 0.1)
$error50: rgba(237, 70, 90, 0.5)
$warning: #f8e71c
$success: #50e3c2
$background: #FBFBFB
$background_alt: #fbfbfb
$btn_yellow: #f4d442
$light_yellow: rgba(255, 244, 229, 1)
$light_yellow_50: rgba(255, 244, 229, 0.5)
$darktext: #666
$darkpurple: #525d7d
$lightpurple: #838fa1
$lighterpurple: #90a1b5
$inventoryBar: #86BCFB
$table_row_alt: #f8f8f8
$background_grey: #F9F9F9
$green: #20b296
$cornflower: #7088f0
$light_periwinkle: #c7d0fa
$pale_grey: #f3f5ff
$ice_blue: #f4f6f6
$light_sky_blue: #dde2e4
$green_teal: #0fb682
$orange: #f57706
