.table__row--expanded .tracker-chart--sticky
  position: sticky
  left: 0
  z-index: 2
  transition: all 0.3s linear
  +desktop
    width: calc(100vw - #{$desktop-side-margin} * 2 - #{$sidebar-full-width})
  +tablet
    width: calc(100vw - #{$tablet-side-margin} * 2 - #{$sidebar-full-width})
  &.tracker-chart--wider
    +desktop
      width: calc(100vw - #{$desktop-side-margin} * 2 - #{$sidebar-collapsed-width})
    +tablet
      width: calc(100vw - #{$tablet-side-margin} * 2 - #{$sidebar-collapsed-width})
.tracker-chart--open
  border: 1px solid $secondary_grey
  background-color: $white
  margin-top: -1px
  .tracker-chart__header
    +body-header
    color: $slate
    font-size: 15px
    display: flex
    justify-content: space-between
    align-items: center
    margin: 40px 75px 0
    .tracker-chart__toggle
      display: flex
      justify-content: space-around
      align-items: center
  .tracker-chart__container
    height: 100%
    font-weight: 300
    padding-bottom: 30px
    .axis-labels text
      font-size: 12px
      font-weight: 400
      +large-desktop
        font-size: 10px

    .grid,
    .primary-x-axis,
    .primary-y-axis, 
    .secondary-y-axis,
    .tertiary-y-axis
      .domain
        opacity: 0
      .tick
        text
          fill: $slate
          font-size: 10px
          +large-desktop
            font-size: 8px
        line
          stroke: $secondary_grey
          opacity: 0

    .grid .tick line
      stroke-width: 0.5px

    .tertiary-y-axis .tick text
      fill: $medium_blue

    .grid .tick line,
    .primary-x-axis .tick line
      opacity: 1
      stroke-width: 0.5px

    .primary-x-axis .tick text
      transform: translateY(3px)
  
    .circle
      transition: all 50ms ease

    .crosshairs
      display: none

    .focusLine
      fill: none
      stroke: $cool_grey_dark
      stroke-width: 0.75px
      stroke-dasharray: 4