.table--tracker__wrapper
  position: relative
  overflow: hidden
  &.table--tracker__wrapper--overflow-left,
  &.table--tracker__wrapper--overflow-right
    .table__cell--product-name .table__cell__column:not(.table__cell__column--image)
      max-width: 300px
  .table-header-container
    z-index: 1

.table--tracker__wrapper--overflow-left:before,
.table--tracker__wrapper--overflow-right:after
  content: ''
  height: calc(100% + 20px)
  position: absolute
  top: -10px
  width: 20px

.table--tracker__wrapper--overflow-left:before
  left: 430px
  box-shadow: 8px 0 10px -6px rgba(0,0,0,0.35)
  z-index: 2

.table--tracker__wrapper--overflow-right
  &:after
    right: -20px
    box-shadow: -8px 0 10px -5px rgba(0,0,0,0.35)
    z-index: 1

  .table--tracker .table__row--tracker .table__row__cell-wrapper:hover .table__cell--actions
    position: sticky
    right: -1px
    background: none
    background-image: linear-gradient(to left, $light_yellow, $light_yellow, $light_yellow, $light_yellow_50)
    -webkit-backface-visibility: hidden
    &.table__cell--header
      border-left: 0
      background: transparent