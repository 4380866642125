.admin-combination-types
  flex: 1
  display: flex
  flex-direction: column

  .admin__column.new-form
    margin: 0

  .admin-combination-types__line-items
    .line-items__row
      justify-content: flex-start
      .line-items__cell
        flex: 1
      .line-items__cell--actions
        flex: 0.2