.extension__resources
  display: flex
  align-items: stretch
  margin-top: 30px
  flex-direction: row
  justify-content: space-around
  +phone
    flex-direction: column
  .extension__resource
    flex-basis: 325px
    border: 1px solid $secondary_grey
    border-radius: 2px
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px
    transition: all 150ms ease
    color: $slate
    &:hover
      background-color: $lightest_grey
    &:first-child
      margin-right: 10px
    &:last-child
      margin-left: 10px
    +phone
      flex: 1
      &:first-child
        margin-bottom: 20px
    img
      margin-bottom: 15px
    .extension__resource__content,
    .extension__subheader
      align-self: stretch
      text-align: center
      max-width: 275px
      margin: 0 auto
    .extension__subheader
      margin-bottom: 15px