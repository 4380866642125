@import ~../../../../../css/global/colors
@import ~../../../../../css/global/text_styles
@import ~../../../../../css/global/variables

@keyframes customFade
  0%
    opacity: 0.5
  100%
    opacity: 1
.hidden
  display: none
.modal
  display: flex
  align-items: center
  justify-content: center
  .modal-close-icon
    z-index: 7402
    display: flex
    cursor: pointer
    +phone
      right: 25px
  .modal-container
    align-self: end
    animation: 0.5s ease-out 0s 0.5 customFade
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35)
    position: fixed
    max-width: 98%
    background-color: $white
    border: 1px solid $secondary_grey
    z-index: 100001
    display: block
    border-radius: 4px
    overflow-y: auto
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    .modal-header
      +modal-title
      padding: 14px 20px
      border-bottom: 1px solid $secondary_grey
      z-index: 1
      display: flex
      align-items: center
      line-height: 1.33
      label
        flex-grow: 2
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        padding-right: 8px
      svg
        width: 14px
        height: 14px
        flex-shrink: 0
    .empty-modal-header
      border: none
      justify-content: flex-end
    .modal-content
      +body
      word-wrap: break-word
      padding: 16px 20px 20px
    &.info-modal
      max-width: 500px
      .modal-header
        color: $core
        font-size: 18px
      .info-modal__button
        margin: 0px auto
    &.presentation-modal
      max-width: 425px
      &.presentation-modal--small
        max-width: 400px
        .modal-content
          padding: 20px
        .presentation-modal__body
          text-align: center
      &.presentation-modal--wide
        max-width: 500px
      &.presentation-modal--padded
        padding: 5px 15px
      .modal-header
        border: none
      .presentation-modal__image
        margin: 0 auto 20px
        display: block
        max-height: 150px
      .presentation-modal__header
        +onboarding-presentation
        text-align: center
      .presentation-modal__subheader
        +onboarding-explanation
        text-align: center
        margin: 20px 10px
      .presentation-modal__body
        +general-subtitle
        font-size: 12px
        margin: 20px 10px
      .presentation-modal__feature
        color: $dark
        font-weight: 600
      .presentation-modal__list
        padding-bottom: 20px
      .presentation-modal__tiny
        font-size: 11px
        padding: 10px 5px 0
      .presentation-modal__sublink
        color: $slate_75
        cursor: pointer
        text-align: center
      button.presentation-modal__button
        width: 100%
        max-width: 350px
        margin: 0 auto 10px
  .modal-backdrop
    width: 100%
    height: 100%
    background-color: $black_60
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    display: block
    z-index: 100000
  &.hidden
    display: none
