.tracker-categories
  display: flex
  .tracker-categories__tabs
    display: flex
    overflow: auto
    margin-top: -50px
    padding-top: 50px
  .category-tab
    +page-header
    font-weight: 500
    flex-basis: 200px
    border-top-left-radius: 6px
    border-top-right-radius: 6px
    background-color: $background
    border: 1px solid $secondary_grey
    font-size: 14px
    height: 55px
    padding: 0px 15px
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer
    white-space: nowrap
    position: relative
    &:first-child:not(.category-tab--overview)
      border-left: none
    .tooltip__wrapper
      position: initial
      &:first-child .tooltip__content--actions
        right: 25px
      &:last-child .tooltip__content--actions
        right: 10px
      .tooltip__content
        left: auto
        bottom: calc(100% - 10px)
        padding: 7.5px 10px
        &.tooltip__content--actions
          ::after
            right: 7px
            left: auto
        &.tooltip__content--uncategorized
          margin-left: 5px
  .category-tab--overview
    flex-basis: 150px
    z-index: 1
  .category-tab--add
    flex-basis: 75px
    justify-content: center
    line-height: 20px
    .category-tab--add__icon
      font-size: 40px
      font-weight: 200
  .category-tab--active
    background-color: $white
    border-bottom: $white
  .category-tab--disabled
    pointer-events: none
    opacity: 0.35
  .category-tab--dragging-over
    background-color: $core_10
  .category-tab--product-just-added
    animation: add-to-tracker 2s ease
  .category-tab--is-being-dragged
    cursor: -webkit-grabbing
  .category-tab--arrow
    flex-basis: 50px
    padding: 0
    justify-content: center
    z-index: 10
    position: relative
    &.category-tab--left
      left: 0
      border-left: 1px solid $secondary_grey
      box-shadow: 3px 0px 2.5px 0px $black_10
      overflow-y: hidden
    &.category-tab--right
      width: 50px
      box-shadow: -3px 0px 2.5px 0px $black_10

  .tracker-categories__filler
    flex-grow: 1
    border-bottom: 1px solid $secondary_grey

  .category-tab__icons
    display: flex
    justify-content: flex-end
    align-items: center
    padding-left: 5px
    .category-tab__quantity
      padding: 1px
      min-width: 22px
      background-color: $light
      color: $slate
      border-radius: 2px
      margin-left: 7px
      font-size: 12px
      font-weight: 600
      line-height: 1.5
      text-align: center
    .category-tab__quantity--empty
      background-color: $secondary_grey

  .category-tab__input
    padding-left: 5px
    border: none
    background: transparent
