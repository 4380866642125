.registrations__info
  +flex-column
  padding: 30px
  +desktop
    padding-top: 75px
  .registrations__info__content
    &.registrations__info__content--combination
      margin-bottom: 20px
      max-width: 750px
      width: 100%
      align-self: center
      background-color: lighten($medium_blue, 25%)
      border: 1px solid $medium_blue
      padding: 20px
      border-radius: 3px
      .registrations__info__title
        +card-title
        font-weight: 600
        padding-bottom: 10px
    
    &.registrations__info__content--discount
      position: relative
      display: flex
      justify-content: center
      align-items: center
      +tablet
        +flex-column
        align-items: center
      .registrations__info__title
        flex-basis: 60%
        +tablet 
          flex-basis: 100%
      .registrations__info__content--discount__description
        +card-title
        font-size: 28px
        font-weight: 600
        padding-bottom: 10px
        text-align: center
      .registrations__info__content--discount__old-price
        text-decoration: line-through
        font-size: 20px
        font-weight: normal
      .registrations__info__content--discount__badge
        text-transform: uppercase
        font-size: 28px
        color: $white
        border-radius: 50%
        padding: 20px 25px
        background-color: $slate
        display: flex
        align-items: center
        justify-content: center
        text-align: center
      
  .registrations__info__title
    +headline
    letter-spacing: normal
    margin: 10px 0 15px 0
    font-weight: bold
    display: flex
    strong
      color: $dark
  
.registrations__info__link
  +body-small
  padding: 20px 0px
  color: $slate
  &.registrations__info__link--small
    padding-top: 0
  .registrations__info__link--highlight
    color: $dark
    font-weight: bold
    cursor: pointer
    transition: color 150ms ease
    display: inline