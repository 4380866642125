.admin
  .filters__count
    +card-title
    margin-right: auto
    padding-left: 15px

  .filters.filters--admin-users,
  .filters.filters--admin-membership-types,
  .filters.filters--admin-stripe-plans,
  .filters.filters--admin-extension-types,
  .filters.filters--admin-combination-types,
  .filters.filters--admin-lookup
    .filters__header
      margin-top: 10px
    .filters__section
      flex: 1
      border-top: none
      +desktop
        &:not(:first-child)
          border-left: none
        &:not(:last-child)
          border-right: none