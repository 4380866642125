.keyword-engine
  @media only screen and (min-width: 1668px)
    .table--keyword-engine
      overflow: inherit
  .table__cell--header
    background-color: $background_alt
    justify-content: flex-start
  .table__cell
    min-width: 150px
  .table__cell--checkbox
    min-width: 55px !important
    flex-grow: .1
    &.table__cell--header
      padding: 0
    .table__cell__column
      margin: auto
    .table__title
      height: 100%

  .table__cell--keyword-name a
    color: $slate

  .table__cell--secondary
    min-width: 200px

  .table__results
    margin-top: 0
    border-top: none
    button
      margin-right: 0

.table__row--keyword-engine
  &:nth-child(even) > .table__row > div.table__cell
    background: $table_row_alt

.table--keyword-engine-feature
  .table__cell--header
    .table__cell__section--text
      font-weight: 500
    .table__title
      color: $cool_grey
    .sortable-icon
      display: none

  .table__row--keyword-engine .table__cell
    margin-top: 0
    border-top: 0
    background: $cool_grey
    .table__cell__section--full
      padding-bottom: 0px
      color: #F2F4F5
      a
        color: #F2F4F5
    .table__cell__section--text
      font-weight: 500
    .table__cell__section span.unavailable
      color: $cool_grey

  .table__row--product
    .table__cell
      margin-top: 0
      border-top: none
      align-items: center
      padding: 5px 15px
    .keyword-engine__product__image
      max-width: 75px
      width: auto
      height: auto
      margin-right: 15px
    a
      color: $core
      display: flex
      align-items: center
    .table__cell__section--text
      font-weight: 500
    .table__cell__section--flag
      align-items: center
      font-weight: normal
      img
        margin-right: 3px
