.filters
  position: relative
  .filters__header
    +page-header
    margin: 50px 0px 0px
    display: flex
    flex-direction: row
    justify-content: space-between
    border-radius: 0
  .filters__row
    display: flex
    flex-direction: row
    align-items: stretch
    flex-basis: 100%
    opacity: 1
    +tablet
      flex-direction: column
  .filters__row--search
    padding: 20px 0px 0px
    margin-bottom: 10px
    display: flex
    justify-content: space-between
    align-items: center
    +tablet 
      flex-direction: column
  .filters__row--aggregations
    cursor: pointer
    &.filters__row--collapsed
      display: none
  .filters__row--collapsed
    opacity: 0
    position: absolute
    width: 100%
    z-index: -10
  .filters__column
    display: flex
    flex-direction: column
    flex: 1
    &.filters__column--mini
      align-items: stretch
      margin-top: 0
      padding: 12px
      .filters__title
        margin: 0 0 10px 0
  .filters__section
    margin: 0
    display: flex
    flex-direction: column
    align-items: stretch
    border-radius: 0
    position: relative
    &:last-child
      flex: 1
    .loading__wrapper
      background: transparent 
    .composite-operator-filter
      display: flex
      &.date 
        .operator-section
          flex: 2
          display: inline-flex
          align-items: center
          margin-right: -15px
          &::after
            content: '-'
            padding-left: 10px
            color: $slate
            font-size: 20px
            font-weight: 300
        .operand-section
          padding-left: 15px
      .operator-section
        flex: 1
      .operand-section
        flex: 2
        margin-left: 10px
        .operand-element
          margin-top: 0
          width: 100%

  .filters__section--tier,
  .filters__section--marketplace
    flex-direction: row
    height: 75px
  .filters__section--marketplace
    align-items: center
  .filters__section--aggregations
    flex-direction: row
    justify-content: space-between
    align-items: center
  .filters__section--buttons
    flex-direction: row
    justify-content: flex-end
    align-items: flex-end

  .filters__title
    +card-title
    margin: 5px 0px 15px
    display: flex
    align-items: center

  .filters__title--user-search
    margin: 10px 0px 10px

  .filters__title--marketplace
    +card-title
    display: flex
    align-items: center
    flex-basis: 175px

  .filters__title--slider
    +card-title
    cursor: pointer
    display: flex
    align-items: center
    margin: 5px 0px 15px
    .fa 
      padding-left: 5px 
      color: $secondary_grey
      font-size: 13px

  .filters__title--aggregations
    display: flex
    align-items: center
    margin: 0

  .filters__min-max
    display: flex
    flex-direction: row
    flex-wrap: wrap
    .filters__min-max__group
      flex: 50%
      display: flex
      justify-content: space-between
      align-items: stretch
      padding-bottom: 5px
      &:nth-child(even)
        padding-left: 8px
      &:nth-child(odd)
        padding-right: 8px
      +tablet 
        flex: 100%
        padding: 0
    .filters__min-max__icon
      display: flex
      align-items: center
      margin-right: 5px
      img 
        width: 15px
        height: 15px
      &.filters__min-max__icon--arrow
        margin-right: 0
        img
          width: 10px
          height: 10px
    .filters__min-max__input
      +filter-input
      margin: 5px
      padding-left: 10px
      width: 20px
      min-height: 35px
      height: 35px

.filters--keyword
  .filters__sliders
    display: flex
    flex-wrap: wrap
  .filters__slider__wrapper
    flex-basis: 50%
    max-width: 50%
    border: 0
    margin: 0
    display: flex
    flex-direction: column
    align-items: stretch
  .filters__slider
    color: $slate