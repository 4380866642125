.authy
  display: flex
  justify-content: center
  .sessions-authy-modal
    display: flex
    flex-direction: column
    width: 300px
    height: 230px
    overflow-y: hidden
    .authy-modal-content
      display: flex
      flex-direction: column
      padding-bottom: 0
      .authy-description
        display: flex
        justify-content: center
        margin-bottom: 10px
        +body-subtitle-left
      .authy-note
        +body-legal
        margin-bottom: 10px
      button  
        margin: 15px 0px

.authy-signup-modal
  display: flex
  justify-content: center
  +body-form
  .authy-modal-container
    display: flex
    flex-direction: column
    .react-phone-number-input
      margin: 10px 0 30px 0
      input
        +body-form
        width: 200px