.admin-user__section--notes
  font-size: 14px
  .admin-user-notes-content
    flex: 1
    max-height: 300px
    overflow-y: scroll
    .admin-user-note-form
      flex: 1
      display: flex
      flex-direction: column
      textarea
        border: 1px solid $secondary-grey
        padding: 10px
        resize: none
        transition: 400ms background ease-in
        height: 100px
      .user-form-submit
        display: flex
        flex-direction: row
        align-self: flex-end
        margin-top: 10px
        button:last-child
          margin-left: 20px
    .actions
      +flex-row
  .inline-text
    white-space: pre-line
