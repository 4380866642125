.keyword-list-modal
  .modal-header.keyword-list-modal-header
    font-size: 18px
    line-height: 20px
    color: $slate

  .modal-content.keyword-list-modal-content
    color: $slate

    textarea
      height: 190px
      width: 510px
      padding: 6px 11px
      resize: none
      border-color: $secondary_grey
      line-height: 1.6
      &::placeholder
        color: $secondary_grey

    input
      width: 510px
      font-size: 12px
      line-height: 18px
      &::placeholder
        color: $secondary_grey

    textarea
      outline: none

    button
      margin: 8px 0
      &:disabled
        opacity: 0.5

    .subtext
      font-size: 12px
      line-height: 18px
      margin-bottom: 12px
      width: 510px

    .heading
      font-size: 15px
      font-weight: 600
      line-height: 20px
      margin-bottom: 8px
