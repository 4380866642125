.registrations
  .registrations__checkout.registrations__checkout--variation
    display: flex
    align-items: stretch
    align-self: center
    flex-wrap: wrap
    margin: 0
    max-width: 1100px
    +desktop
      margin: 0 30px

    .registrations__checkout__block
      +flex-column
      flex-basis: 50%
      padding: 30px
      +tablet
        flex-basis: 100%
        padding: 20px
        &.no-border-right
          border-right: 1px solid $secondary_grey
      &.registrations__checkout__block--full
        flex-basis: 100%
        padding: 30px 0px
      &.registrations__checkout__block--no-padding
        padding: 0

    .registrations__logo
      margin: 20px auto
      max-width: 500px
      width: 100%

    label
      color: $slate

    .registrations__form
      max-width: 750px
      width: 100%
      margin: 0 auto
      display: flex
      flex-wrap: wrap
      input
        padding-left: 10px
        flex: 0
        &:focus
          border-color: $cool_grey
          outline: 0
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)
    .registrations__form__input
      flex-direction: column
      justify-content: flex-start
      &.registrations__form__input--full
        flex-basis: 100%      
      &.registrations__form__input--half.registrations__form__input--padding
        +desktop
          padding-right: 10px
      &.registrations__form__input--half
        flex-basis: 50%
        +tablet 
          flex-basis: 100%
      &.registrations__form__input--quarter
        width: 25%
        +tablet 
          flex-basis: 100%
      &.registrations__form__input--spaced
        padding-top: 15px
      &.registrations__form__input--padding
        +desktop
          padding-right: 10px
      &.registrations__form__input--empty
        select 
          color: $cool_grey
      select
        +body
        color: $slate    
        font-size: 15px
        letter-spacing: -0.2px
        width: 100%
        height: 40px
        margin: 5px 0
        background-color: $white
        border: 1px solid $secondary_grey
        padding-left: 10px
        &:focus
          border-color: $cool_grey
          outline: 0
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)

    .registrations__title
      +onboarding-title
      line-height: 1.25
      color: $slate
    
    .registrations__subtitle
      +onboarding-sub-title
      line-height: 1.44
      color: $slate
      font-weight: normal
      padding: 10px 0 30px 0
      &.registrations__subtitle--center
        text-align: center
        display: flex
        justify-content: space-around
        align-items: center
        color: $secondary_grey
        .registrations__subtitle__line
          display: inline-block
          border: none
          border-top: 1px solid $secondary_grey
          flex: 1
          margin: 0 10px

    .trial
      +body
      color: $cool_grey
      padding-bottom: 10px
      font-weight: normal
      font-size: 11px
      text-align: center