.keyword
  flex: 1
  display: flex
  flex-direction: column
  +desktop
    margin: 30px
  +tablet
    margin: 10px

  .keyword__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center

  .keyword__title
    +card-title
    display: flex
    align-items: center

  .table__results
    .table__title
      font-size: 15px
