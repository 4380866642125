.account 
  flex: 1
  display: flex
  flex-direction: column
  +desktop
    margin: 30px 30px 90px
  +tablet
    margin: 30px 15px 90px

  .account__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end
  .account__buttons--left
    justify-content: flex-start
  .account__buttons--center
    flex-direction: column
    justify-content: flex-start
    align-items: center
    button
      margin: 10px 5px
  .account__buttons--bottom
    align-items: flex-end
  .account__buttons--full
    flex-direction: column
    align-items: stretch
    justify-content: flex-start

  .account__title
    +body-subtitle-left
  .account__subtitle
    +label
    margin-bottom: 5px
  .account__description
    +title-description
    color: $slate
    margin-top: 3px
    &.account__description--error
      color: $error
      font-weight: 500

  .account__row
    display: flex
    margin-bottom: 20px
    +desktop
      flex-direction: row
    +tablet
      flex-direction: column

  .account__column--left
    background: transparent
    border: none
    flex: 1
    display: flex
    flex-direction: column
    align-items: flex-start
  .account__column--right
    flex: 2
    display: flex
    flex-wrap: wrap
    flex-direction: row
    align-items: stretch
  .account__section // TODO: retire this?
    flex-basis: 50%
    padding: 10px
    +tablet 
      flex-basis: 100%
  .account__section--full
    flex-basis: 100%
  .account__section--baseline
    display: flex
    align-items: flex-end
    margin-bottom: 5px    
  .account__section--inline 
    .form__input
      display: flex
      flex-direction: row
      justify-content: space-between
    .form__label
      margin: 0
      display: flex
      align-items: center
      
  .account__subsection
    padding-bottom: 15px
    flex: 1
    display: flex
    flex-direction: column

  input::placeholder
    color: $cool_grey

  .modal .modal-header
    font-size: 18px
    font-weight: 600

.cancel__content 
  padding-bottom: 15px
  max-width: 400px