.admin
  input::placeholder,
  .Select-placeholder,
  .Select--single > .Select-control .Select-value
    color: $brown_grey

  .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label
    color: $slate

  .Select--single > .Select-control
    border-color: $secondary_grey

  .table-row,
  .form__field--inline
    .Select-control
      height: 24px
      margin: 3px 0px
    .Select-input
      height: 30px
      input
        padding: 0
    .Select-placeholder
      line-height: 30px

    input
      height: 36px
      min-height: 30px
      font-size: 14px
      padding-left: 10px

    .Select
      flex: 1

  .SingleDatePicker,
  .SingleDatePickerInput__withBorder,
  .DateInput,
  .DateInput__small
    width: 100%
    max-width: 200px
  .SingleDatePickerInput__showClearDate
    padding-right: 0
