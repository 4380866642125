.admin-lookup
  flex: 1
  display: flex
  flex-direction: column
  margin-top: 10px

  .filters.filters--admin-lookup
    .card-section
      width: 100%
    .filters__section
      flex: none
      +desktop
        flex-basis: 300px
      &:last-child
        flex: 1
        flex-direction: row
        align-items: flex-end
        +desktop
          button
            margin-bottom: 20px
            min-height: 36px
    .form-validation-error
      height: 20px

  .admin-lookup__row
    flex: 1
    display: flex
    align-items: stretch
    flex-direction: column

    .admin-lookup__row__cell
      display: flex
      flex-direction: column
      .list-table
        display: flex
        flex-direction: column
        .row-cell
          .values-list
            padding-left: 0
            list-style-type: none
            width: 100%
      .list-table-header
        margin-top: 0
        border-top: 0
      .card-section--header
        margin-top: 0

  .marketplace-selector
    max-width: 100%
    border: 1px solid $secondary_grey