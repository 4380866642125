.login 
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center

.login__content
  width: 100%
  max-width: 455px
  margin-bottom: 30px
  margin-top: 10px
  +onboarding-sub-title()
  +phone
    padding: 0 20px
  &.login__content--additional-user
    .registrations__info__link
      padding: 0 0 10px
      cursor: pointer
      transition: all 150ms ease
      &:hover
        color: $dark
    .login__header,
    .registrations__form
      flex-shrink: 0
    .registrations__form__input
      margin-top: 10px
    .toc
      margin: 10px 5px 0px


.login__header
  display: flex
  flex-direction: column
  align-items: center

.login__subtitle
  +onboarding-subtitle

.login__modal
  .login__modal__content
    width: 455px
    +phone
      width: 300px

.login__modal--confirm-purchase
  .modal-confirmation-content
    +card-title
    padding: 0 25px 25px