.admin
  flex: 1
  display: flex
  flex-direction: column

  +desktop
    margin: 0 30px
  +tablet
    margin: 0 10px

  .Select-option.is-focused
    background-color: $light
    color: $white

  .table-row
    border-bottom: 1px dotted $secondary-grey
    padding: 5px 0
    &:last-child
      border-bottom: none
    &.align-left
      justify-content: flex-start
    &.comfortable-heigth
      height: 57px
    .content
      +body-form

  .admin__header
    +page-header
    margin: 0px
    display: flex
    flex-direction: row
    justify-content: space-between
    border-radius: 0

  .admin__subheader
    +card-title
    font-size: 16px
    display: flex
    align-items: center
    justify-content: space-between

  .admin__description
    +body
    padding-bottom: 0

  .admin__buttons
    flex: 1
    display: flex
    flex-direction: row
    justify-content: flex-end
    margin: 7.5px 2.5px
    &.admin__buttons--extra-space
      margin-top: 10px

  .admin__actions
    +body
    flex-basis: 300px

  .admin__title
    +card-title
    display: flex
    align-items: center

  .admin__row
    display: flex
    +tablet
      flex-direction: column

  .admin__column
    flex: 1
    display: flex
    flex-direction: column
    +desktop
      &:first-child
        margin-right: 15px
      &:last-child
        margin-left: 15px
      &:first-child:last-child
        margin: 0

  .admin__sticky-footer
    background: white
    height: 4em
    padding: 10px
    position: fixed
    bottom: 0
    margin-left: -15px
    margin-bottom: -1px
    width: 63%
    box-shadow: 0px -5px 10px -7px rgba(0, 0, 0, 0.57)
