.fee-and-profit
  &.fee-and-profit--breakdown
    width: 400px
  .fee-and-profit__description
    +card-title
    font-size: 13px
    display: flex
    align-items: center
    margin: 5px 0px 30px
    flex-basis: 100%
    img
      max-width: 50px
      width: 100%
      height: auto
      margin-right: 15px

  .fee-and-profit__column
    +flex-column
    flex: 1
    min-width: 375px
    justify-content: flex-start
    &:last-child
      padding-left: 30px

  .fee-and-profit__row
    display: flex
    justify-content: space-between
    align-items: stretch
    .form__label
      +card-title
      white-space: nowrap
      flex: 1
    input
      width: 220px
      padding-left: 10px
      flex: none
    .form__currency
      padding-left: 22px
      &.form__currency--extra-padding
        padding-left: 40px
    &.fee-and-profit__row--feature
      display: flex
      justify-content: space-between
      align-items: center
      padding: 10px 0px 0px
      margin-top: 10px
      border-top: 1px solid $slate
      .form__input
        font-weight: 600
        font-size: 17px
    &.fee-and-profit__row--breakdown .form__label
      padding-right: 30px

  .fee-and-profit__price-wrapper
    position: relative
    flex-basis: 190px
    display: flex
    align-items: center
    div
      position: absolute
      left: 10px
      font-size: 14px
  
  .fee-and-profit__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end
    flex-basis: 100%
    padding-top: 25px