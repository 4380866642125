.tracker__groups
  font-weight: 600

  .tracker__groups__action-bar
    display: flex
    flex-wrap: wrap

  .add-to-tracker
    flex: 1.5
    font-size: 15px
    +tablet
      flex-basis: 100%
    .add-to-tracker__section
      display: flex
      align-items: center
      justify-content: space-between
      flex: 1
      border: 1px solid $secondary_grey
      border-radius: 4px
      margin: 0 10px 0 15px
    .add-to-tracker__input
      padding-left: 10px
      background: $white
      border: none
      border-right: 1px solid $secondary_grey
      border-radius: 0
      font-size: 13px
      height: 20px
      min-height: 20px
      flex: 2
    .marketplace-selector
      border: none
      flex: 1
      max-width: none
      margin: 0
      height: 30px
      font-weight: 500
  .tracker__groups__columns-selector--group
    .multiselect
      margin-left: 15px
      flex: 1
      .trigger
        height: 30px
        .title
          padding-right: 10px
          +body-header
  .tracker__groups__columns-selector--group
    padding: 15px 0 0 0
    flex-basis: 100%
    & > :first-child
      margin-left: auto
    .multiselect
      max-width: 200px
      background-color: $white

  .tracker__groups__empty-search
    flex-basis: 100%

  .tracker__aggregations
    flex-basis: 100%
    display: flex
    justify-content: space-around
    position: relative
    +tablet
      flex-wrap: wrap
      .tracker__aggregation-card
        min-width: 225px
    .tracker__aggregation-card
      flex: 1
      margin-right: 10px
      margin-left: 10px
      flex-direction: column
      align-items: stretch
      +desktop
        &:first-child
          margin-left: 0
        &:last-child
          margin-right: 0