.keyword__drawer
  flex-basis: 100%
  display: flex
  flex-direction: column
  align-items: stretch
  margin-top: 0
  border-top: 0
  padding-top: 5px
  .drawer__header
    display: flex
    align-items: stretch
    flex-basis: 50px
    .drawer__tab
      +body-subtitle
      font-weight: 500
      flex: 1
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      border: 1px solid $secondary_grey
      border-top-left-radius: 6px
      border-top-right-radius: 6px
      &.open
        background-color: $white
        border-bottom: none
      &.closed
        background-color: $background
  .drawer__content
    border-top: 0
    margin-top: 0
    padding: 0
    display: flex
    align-items: stretch
  .drawer__content--products
    flex-direction: column
    .table__cell
      flex-direction: row
      justify-content: flex-start
      margin-top: 0
      &:first-child
        border-left: 0
      &:last-child
        border-right: 0
    .table__cell--product-name
      flex-basis: 45%
      justify-content: flex-start
    .table__cell__column--actions
      flex: 0
      margin-left: auto
      .tooltip__wrapper
        align-self: center
  .drawer__content--data
    .top-fifty-wrapper
      margin-left: -10px
    .drawer__column
      padding: 15px
    .drawer__tips
      +body
      padding: 15px 0px
    .drawer__column--cloud
      flex: 2
    .drawer__column--tips
      flex: 1
    .drawer__title
      +card-title
      i
        padding-left: 15px
        color: $core
        font-size: 15px
        cursor: pointer