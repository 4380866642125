.extension__bullets
  margin: 30px 0 0
  +desktop
    column-count: 2
  .extension__bullet
    flex: 1
    display: flex
    margin-bottom: 30px
    line-height: 1.75
    -webkit-column-break-inside: avoid
    page-break-inside: avoid
    break-inside: avoid-column
    +desktop
      &:nth-child(even)
        margin-bottom: 0
    .extension__bullet__number
      width: 35px
      height: 35px
      margin-right: 15px
      font-size: 18px
      font-weight: 500
      background-color: $dark
      color: $white
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      padding-bottom: 2px
    .extension__bullet__title
      font-weight: 600
      padding-bottom: 5px
      font-size: 14px
